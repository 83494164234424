import React, { useCallback, useState, useContext, createContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useInterval } from "../../hooks";
import useGatsbyAuth from "../../templates/user-account/hook/useGatsbyAuth";
import { getAccountCreatedStatus } from "../../utils/helper";
import { loadMembershipData, updateAliasContext } from "../../templates/user-account/utility";

const UserContext = createContext({
	user: null,
	isLoggedIn: false,
	affiliate: {
		code: null,
		name: null,
	},
	isRegistrationRedirect: false,
	profileTab: null, // my-trips | profile
});

const useUser = () => useContext(UserContext);

const UserProvider = ({ children, lang }) => {
	const { loading: authServiceLoading, authState, authService } = useGatsbyAuth();
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState();
	const [affiliate, setAffiliate] = useState({
		code: "btd",
		name: null,
	});
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isRegistrationRedirect, setIsRegistrationRedirect] = useState(true);
	const [profileTab, setProfileTab] = useState();

	const updateUser = _user => {
		setLoading(false);
		setUser(_user);

		if (!authServiceLoading && authState && _user) {
			if (authState?.isAuthenticated) {
				setIsLoggedIn(true);
			} else {
				setIsLoggedIn(false);
				authService.tokenManager.clear();
			}
		}
	};

	const refreshSession = useCallback(async () => {
		if (document.visibilityState === "visible") {
			await authService.session
				.get()
				.then(async session => {
					// If the session is valid & active, get the user data and update the user
					if (session.status === "ACTIVE") {
						session
							.user()
							.then(async sessionUser => {
								// Flag for Sunwing Agents
								const isAgent = !!authState?.idToken?.claims?.agentGlobalId;
								updateUser(sessionUser);
								if (!isAgent) {
									// Update affiliate alias
									if (authState && authState.accessToken) {
										await loadMembershipData(authState, lang).then(affiliatePayload => {
											if (affiliatePayload !== null) {
												updateAliasContext(affiliatePayload, setAffiliate);
											}
										});
									}
								}
							})
							.catch(error => {
								console.error("Session User error: ", { error });
							});
					} else if (document.visibilityState === "visible") {
						updateUser();
					}
				})
				.catch(() => {
					// If no session, clear the user.
					if (document.visibilityState === "visible") {
						updateUser();
					}
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authState]);

	useEffect(() => {
		// On load
		if (!authServiceLoading && authState && !getAccountCreatedStatus()) {
			(async () => {
				await refreshSession();
			})();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authServiceLoading, authState]);

	useEffect(() => {
		// Check the session on window focus
		window.addEventListener("visibilitychange", refreshSession);

		return () => {
			window.removeEventListener("visibilitychange", refreshSession);
		};
	}, [refreshSession]);

	// Check the session and update the authState every 5 minutes
	useInterval(refreshSession, 5 * 60 * 1000);

	const value = {
		loading,
		isLoggedIn,
		setIsLoggedIn,
		user,
		updateUser,
		authState,
		authService,
		affiliate,
		setAffiliate,
		isRegistrationRedirect,
		setIsRegistrationRedirect,
		refreshSession,
		profileTab,
		setProfileTab,
	};

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
	lang: PropTypes.string.isRequired,
};

export { UserProvider, useUser };
