import React, { createContext } from "react";
import { oneOfType, arrayOf, node, object } from "prop-types";

const PageContext = createContext({});

const PageContextProvider = ({ data, children }) => (
	<PageContext.Provider value={data}>{children}</PageContext.Provider>
);

PageContextProvider.propTypes = {
	data: object.isRequired,
	children: oneOfType([arrayOf(node), node]).isRequired,
};

export default PageContext;
export { PageContextProvider, PageContext };
