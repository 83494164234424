/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from "react";
import * as dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import { RCLProvider } from "./src/components/RCL";
import { AlertsProvider } from "./src/context";
import { SecurityProvider } from "./src/templates/user-account/context/SecurityProvider";
import { PageContextProvider } from "./src/components/PageContext";
import {
	exitIntentCookieName,
	emailCookieName,
} from "./src/components/EmailAcquisitionForm/constants";

import "./src/styles/index.scss";

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
	const lang = props?.pageContext?.locale?.substring(0, 2);
	dayjs.locale(lang === "fr" ? "fr" : "en");
	if (window.olark && typeof window.olark === "function") {
		if (lang === "fr") {
			window.olark("api.chat.setOperatorGroup", {
				group: process.env.GATSBY_OLARK_GROUP_FRENCH,
			});
			window.olark("api.box.setLocale", "fr-FR");
		} else {
			window.olark("api.chat.setOperatorGroup", {
				group: process.env.GATSBY_OLARK_GROUP_ENGLISH,
			});
			window.olark("api.box.setLocale", "en-US");
		}
		window.olark("api.chat.onOperatorsAvailable", () => {
			window.olark("api.box.show");
		});
	}

	// Handle Fresh Relevance - Social Proof Banner
	if (!window.socialProofCallback) {
		window.socialProofCallback = () => {
			const socialTrendObj = document.querySelector(".social-trend-wrapper");
			const socialTrendClose = document.querySelector(".social-trend-close");

			const delay = duration =>
				new Promise(resolve => {
					setTimeout(() => resolve(), duration);
				});

			const hideSocialProof = (callback = () => {}) => {
				socialTrendObj.style.opacity = 0;
				delay(2000)
					.then(() => {
						socialTrendObj.style.display = "none";
					})
					.then(callback);
			};

			const handleCloseButton = e => {
				e.preventDefault();
				hideSocialProof();
			};

			if (socialTrendObj) {
				socialTrendObj.style.display = "block";
				delay(1000)
					.then(() => {
						socialTrendObj.style.opacity = 1;
						socialTrendClose.addEventListener("click", handleCloseButton);
					})
					.then(() => delay(7000))
					.then(() => {
						hideSocialProof(() => {
							socialTrendClose.removeEventListener("click", handleCloseButton);
						});
					});
			}
		};
	}

	// Reset any Email Acquistion / Exit Intent tracking
	if (window.sessionStorage) {
		sessionStorage.removeItem(exitIntentCookieName);
		sessionStorage.removeItem(emailCookieName);
	}

	return (
		<SecurityProvider lang={props?.pageContext?.locale}>
			<PageContextProvider data={props?.pageContext}>
				<AlertsProvider
					lang={props?.pageContext?.locale}
					staticAlertsCount={props?.pageContext?.numberOfAlerts}
					timestamp={props?.pageContext?.timestamp}>
					<RCLProvider lang={props?.pageContext?.locale}>{element}</RCLProvider>
				</AlertsProvider>
			</PageContextProvider>
		</SecurityProvider>
	);
};
