const profileMapping = (profileDataLoaded, changedProfileData, profileInitStateProp) => {
	const phoneData = {
		phones: [
			{
				type: "PERSONAL",
				phoneNumber: changedProfileData.mobilePhone,
			},
		],
	};

	const departureData = [
		{
			preferenceType: "PRIMARY",
			code: changedProfileData.preferredGateway,
			name: "",
		},
	];

	const isLanguageFormatLocale =
		profileDataLoaded?.contactPreferences?.languageCode?.indexOf("_") > 0;

	const languageData = {
		contactPreferences: {
			languageCode: isLanguageFormatLocale
				? `${changedProfileData.preferredLanguage}_CA`
				: changedProfileData.preferredLanguage.toUpperCase(),
		},
	};

	// Required fields
	const profileDataUpdated = {
		firstName: changedProfileData.firstName,
		lastName: changedProfileData.lastName,
	};

	// Merge changed object data
	let updatedProfileData = {
		...profileDataLoaded,
		...profileDataUpdated,
	};

	if (changedProfileData.preferredLanguage !== profileInitStateProp.preferredLanguage) {
		if (updatedProfileData?.contactPreferences?.languageCode) {
			updatedProfileData.contactPreferences.languageCode = isLanguageFormatLocale
				? `${changedProfileData.preferredLanguage}_CA`
				: changedProfileData.preferredLanguage.toUpperCase();
		} else {
			updatedProfileData = {
				...updatedProfileData,
				...languageData,
			};
		}
	}

	// Update birthDate if added
	if (
		changedProfileData?.birthDate !== profileInitStateProp?.birthDate &&
		changedProfileData?.birthDate !== ""
	) {
		updatedProfileData = {
			...updatedProfileData,
			birthDate: `${changedProfileData.birthDate}T00:00:00`,
		};
	} else if (changedProfileData?.birthDate === "") {
		delete updatedProfileData.birthDate;
	}

	// Add or update mobile phone array data
	if (changedProfileData.mobilePhone !== profileInitStateProp.mobilePhone) {
		const personalPhone = updatedProfileData?.phones?.filter(phone => phone.type === "PERSONAL");

		if (personalPhone?.length > 0) {
			personalPhone[0].phoneNumber = changedProfileData.mobilePhone;
		} else {
			updatedProfileData = { ...updatedProfileData, ...phoneData };
		}
	}

	// Add or update departure gateway array data
	if (changedProfileData.preferredGateway !== profileInitStateProp.preferredGateway) {
		const primaryGateway = updatedProfileData?.contactPreferences?.departureCities?.filter(
			departure => departure.preferenceType === "PRIMARY"
		);

		if (primaryGateway?.length > 0) {
			primaryGateway[0].code = changedProfileData.preferredGateway;
		} else {
			const contactPreferences = {
				...updatedProfileData,
			};

			contactPreferences.contactPreferences.departureCities = departureData;
			updatedProfileData = { ...updatedProfileData, ...contactPreferences };
		}
	}

	// Handle context update
	const contextData = {
		context: {
			campaign: {
				code: "consumer-account-profile",
				source: process.env.GATSBY_APP_ID,
				channel: "ONLINE",
				type: "SUBSCRIPTION",
			},
			page: {
				url: window.location.href,
				title: document.title,
			},
		},
	};

	updatedProfileData = { ...updatedProfileData, ...contextData };

	return updatedProfileData;
};

const setLoginType = (oktaToken, socialLogins) => {
	const postBody = {
		email: oktaToken.idToken.claims.email,
		contactType: "customer",
	};

	// Determine the social login type, if applicable
	socialLogins.some(login => {
		if (login.id === oktaToken.idToken.claims.idp) {
			postBody.additionalProperties = [
				{
					code: "LastLoginType",
					value: login.type,
				},
			];
			return true;
		}

		return false;
	});

	// Fallback to Okta login type
	if (postBody.additionalProperties === undefined) {
		postBody.additionalProperties = [
			{
				code: "LastLoginType",
				value: "OKTA",
			},
		];
	}

	fetch(`${process.env.GATSBY_OKTA_AUTH_API_URL}/loginType/current`, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${oktaToken.accessToken.accessToken}`,
		},
		method: "PATCH",
		body: JSON.stringify(postBody),
	})
		.then(res => res)
		.catch(error => {
			console.error("setLoginType:: error: ", error);
		});
};

const registerAffiliateMember = async (oktaProfile, code = "", authState) => {
	const payload = {
		id: authState.accessToken?.claims?.gid || "",
		email: oktaProfile?.email || "",
		firstName: oktaProfile?.firstName || "",
		lastName: oktaProfile?.lastName || "",
		contactType: "CUSTOMER",
		additionalProperties: [
			{
				code: "invitationCode",
				value: code.split("|")[0],
			},
		],
	};

	return fetch(`${process.env.GATSBY_OKTA_LOYALTY_API}/v1/invite/accept`, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${authState.accessToken.accessToken}`,
		},
		body: JSON.stringify(payload),
		method: "POST",
	})
		.then(res => {
			if (!res.ok) {
				if (res.status !== 200) {
					return res.json().then(json => {
						if (json?.code) {
							// Catch Loyalty API object format
							// eslint-disable-next-line no-throw-literal
							throw { ...json, type: "custom" };
						} else {
							// eslint-disable-next-line no-throw-literal
							throw { ...json, type: "standard" };
						}
					});
				}
			}
			return res.json();
		})
		.then(res => {
			sessionStorage.removeItem("affiliateCode");
			sessionStorage.removeItem("invitePending");
			return res;
		});
};

const loadMembershipData = async (authState, language = "en") =>
	fetch(`${process.env.GATSBY_OKTA_LOYALTY_API}/v1/loyalty?language=${language}`, {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${authState.accessToken.accessToken}`,
		},
		method: "GET",
	})
		.then(res => {
			if (!res.ok) {
				if (res.status !== 200) {
					return res.json().then(json => {
						throw new Error(`${json.statusCode} ${json.message}`);
					});
				}
			}
			return res.json();
		})
		.then(res => res)
		.catch(error => {
			console.error("loadMembershipData:: error: ", error);
			return [];
		});

const updateAliasContext = (affiliatePayload, setAffiliate) => {
	let hasMembership = false;

	affiliatePayload.forEach(program => {
		const programCode = program?.code.toLowerCase();
		const programName = program?.name;
		const isActive = program?.status?.toLowerCase() === "active";

		if (programCode && programName && isActive && !hasMembership) {
			setAffiliate({
				code: programCode,
				name: programName,
			});

			hasMembership = true;
		}
	});
};

const processProperties = (data, propertyObject) => {
	if (typeof data !== "string") {
		return null;
	}

	const mappingData = data.trim().replace(/\n/g, "").split(",");
	const output = [];

	mappingData.forEach(mapping => {
		const propArray = mapping.trim().split(";");

		const properties = { ...propertyObject };

		let propFlag = false;
		let propCounter = 0;

		for (let i = 0; i < propArray.length; i++) {
			// Sanitize array
			const tempString = propArray[i].trim();

			if (tempString.length) {
				propArray[i] = tempString;
			} else {
				propArray[i] = null;
			}

			// Build output array
			if (propArray[i]) {
				// eslint-disable-next-line no-loop-func
				Object.keys(properties).forEach(key => {
					const keyValuePair = propArray[i].split(":");

					if (key === keyValuePair[0]) {
						switch (key) {
							case "text":
							case "target":
							case "code":
							case "name":
								// eslint-disable-next-line prefer-destructuring
								properties[key] = keyValuePair[1];
								propFlag = true;
								propCounter += 1;
								break;
							case "href":
								// eslint-disable-next-line no-case-declarations
								const href = propArray[i].substr(key.length + 1, propArray[i].length - 1);
								properties[key] = href;
								propFlag = true;
								propCounter += 1;
								break;
							default:
								propFlag = false;
						}
					}
				});
			}
		}

		// Update output array
		if (propFlag && propCounter === Object.keys(properties).length) {
			output.push(properties);
			propCounter = 0;
		}
	});

	if (output.length) {
		return output;
	}
	return null;
};

const getMandatoryStatusQMA = data => {
	// Determine if user is missing QMA mandatory mobile phone property data
	const primaryGateway = data?.contactPreferences?.departureCities?.filter(
		departure => departure.preferenceType === "PRIMARY"
	);

	const personalPhone = data?.phones?.filter(phone => phone.type === "PERSONAL");

	return (
		Boolean(data.firstName) === false ||
		Boolean(data.lastName) === false ||
		Boolean(data.mobilePhone) === false ||
		Boolean(primaryGateway?.[0] > 0 ? primaryGateway[0].code : undefined) === false ||
		Boolean(personalPhone?.[0] > 0 ? personalPhone[0].phoneNumber : undefined) === false
	);
};

const updateContext = async (uid, context) => {
	// Update profile context

	const apiRequest = new Request(`${process.env.GATSBY_OKTA_AUTH_API_URL}/client/current`);

	return fetch(apiRequest, {
		headers: {
			"Content-type": "application/json",
		},
		method: "PATCH",
		body: JSON.stringify({
			email: uid,
			contactType: "CUSTOMER",
			additionalProperties: [
				{
					code: "clientId",
					value: context,
				},
			],
		}),
	})
		.then(async res => {
			if (!res.ok) {
				if (res.status !== 204) {
					throw new Error(`${res.status} ${res.statusText}`);
				}
			}
			return res;
		})
		.catch(error => {
			console.error("updateContext method failed: ", error);
			return error;
		});
};

export default setLoginType;
export {
	setLoginType,
	loadMembershipData,
	registerAffiliateMember,
	processProperties,
	updateAliasContext,
	profileMapping,
	getMandatoryStatusQMA,
	updateContext,
};
