import React, { createContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { string, oneOfType, arrayOf, node } from "prop-types";

const RCLContext = createContext("en");

const RCLProvider = ({ lang, children }) => {
	const dictionary = {};

	const { allContentfulApplicationResources: data } = useStaticQuery(
		graphql`
			query RCLQuery {
				allContentfulApplicationResources(
					filter: { application: { elemMatch: { application3charCode: { eq: "SWG" } } } }
				) {
					edges {
						node {
							key
							node_locale
							parseAsMarkdown
							value {
								childMarkdownRemark {
									html
									rawMarkdownBody
								}
							}
							application {
								applicationName
								application3charCode
							}
						}
					}
				}
			}
		`
	);

	for (let i = 0; i < data.edges.length; i++) {
		const item = data.edges[i].node;
		const entryLang = item.node_locale.toUpperCase().substring(0, 2);

		// Create empty object if lang has not been initialized in the dictionary
		if (!dictionary[entryLang]) {
			dictionary[entryLang] = {};
		}

		dictionary[entryLang][item.key] = {
			parseAsMarkdown: item.parseAsMarkdown,
			value: item.parseAsMarkdown
				? item?.value?.childMarkdownRemark?.html
				: item?.value?.childMarkdownRemark?.rawMarkdownBody,
		};
	}

	return (
		<RCLContext.Provider value={{ locale: lang.toUpperCase().substring(0, 2), dictionary }}>
			{children}
		</RCLContext.Provider>
	);
};

RCLProvider.propTypes = {
	lang: string,
	children: oneOfType([arrayOf(node), node]).isRequired,
};

RCLProvider.defaultProps = {
	lang: "en-CA",
};

export default RCLProvider;
export { RCLProvider, RCLContext };
