import { useRef, useEffect } from "react";

const useInterval = (callback, delay) => {
	const savedCallback = useRef(callback);

	// Remember the latest function.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		const id = setInterval(() => {
			if (document.visibilityState === "visible") {
				savedCallback.current();
			}
		}, delay);

		return () => clearInterval(id);
	}, [delay]);
};

export default useInterval;
export { useInterval };
