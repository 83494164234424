import { useState, useEffect } from "react";

const useFilters = value => {
	const [appliedFilters, setAppliedFilters] = useState(value);
	const [hasFiltersChanged, setHasFiltersChanged] = useState(false);
	let numberOfFilters = 0;

	useEffect(() => {
		if (JSON.stringify(appliedFilters) !== JSON.stringify(value)) {
			setHasFiltersChanged(true);
		} else {
			setHasFiltersChanged(false);
		}
	}, [appliedFilters, value]);

	if (value) {
		if (
			value?.destinationSelections.length !== 0 &&
			value?.destinationSelections !== appliedFilters?.destinationSelections
		) {
			numberOfFilters += 1;
		}

		if (value?.priceValue !== appliedFilters?.priceValue) {
			numberOfFilters += 1;
		}

		if (value?.starRatingSelection?.value !== appliedFilters?.starRatingSelection?.value) {
			numberOfFilters += 1;
		}

		if (value?.sortingOptionSelection?.value !== appliedFilters?.sortingOptionSelection?.value) {
			numberOfFilters += 1;
		}
	}

	return {
		hasFiltersChanged,
		setAppliedFilters,
		numberOfFilters,
		appliedFilters,
	};
};

export default useFilters;
export { useFilters };
